import '../SingleProjects.css';
import logo from '../../../assets/projects/HomeOverview.png';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";

function HomeOverview() {
    const {width} = useWindowDimensions();

    return (
        <div className={"about"}>
            <div style={{height: "40px"}} id={"about-me"}/>
            <div className={"single-projectHeading-left"}>
                HomeOverview
            </div>
            <div style={{height: "30px"}}/>
            <div className={"single-projectContent-left"}>

                <img src={logo} alt="Logo" height={"20%"} width={"20%"} className={"img-left"}/>

                <div style={width > 1428 ? {width: "65%"} : {width: "100%"}} className={"single-projectText-left"}>
                I developed homeoverview to provide a nice, simple and graphical way to summarize my internal services. No solution satisfied my wishes, so I developed my own one. Since then I am really happy with it, I use it regularly and it made my home network experience way better. The techstack is also  nextjs and MUI. I also provide good and simple installing instructions on my Github.                    <br />
                    <br />
                    <div className={"buttonGroup"}>

                        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />

                        <a style={{color:"#262626", marginRight:"2%"}} href="https://github.com/zgast/homeoverview/"><i className="fab fa-github fa-2x"></i></a>
                        <a style={{color:"#262626"}} href="https://hub.docker.com/r/zgast/homeoverview"><i className="fab fa-docker fa-2x"></i></a>


                    </div>


                </div>


            </div>



        </div>
    )
        ;
}

export default HomeOverview;
