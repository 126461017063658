import '../SingleProjects.css';
import logo from '../../../assets/projects/EmoteGuesser.png';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";


function EmoteGuesser() {
    const {width} = useWindowDimensions();
    return (
        <div className={"about"}>
            <div style={{height: "50px"}} id={"about-me"}/>
            <div className={"single-projectHeading-left"}>
                EmoteGuesser
            </div>
            <div style={{height: "30px"}}/>
            <div className={"single-projectContent-left"}>
                <img src={logo} alt="Logo" height={"20%"} width={"20%"}/>
                <div style={width > 1428 ? {width: "65%"} : {width: "100%"}} className={"single-projectText-left"}>
                EmoteGuesser was initial a project to learn flutter, in the end, it escalated and actually got re-written. After the alpha release on Github, the project was gradet interesting by many persons on discord and fastly gained 10 stars, on the alpha release day I already got 2 PRs, so I knew I had to finish the project. After some months of development, it was finished and finally published in the Google Play Store and Appstore.
                    <br/>
                    <br/>

                    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
                          rel="stylesheet"/>

                    <a style={{color: "#262626", marginRight: "2%"}} href="https://github.com/zgast/EmoteGuesser/"><i
                        className="fab fa-github fa-2x"></i></a>

                    <a style={{color: "#262626", marginRight: "2%"}}
                       href="https://play.google.com/store/apps/details?id=at.twitch_emote"><i
                        className="fab fa-google-play fa-2x"></i></a>

                    <a style={{color: "#262626", marginRight: "2%"}}
                       href="https://apps.apple.com/at/app/emoteguesser/id1578166484"><i
                        className="fab fa-app-store-ios fa-2x"></i></a>

                    <a style={{color: "#262626"}} href="https://github.com/zgast/EmoteGuesser/releases/tag/1.0"><i
                        className="fas fa-file-download fa-2x"></i></a>

                </div>
            </div>


        </div>
    )
        ;
}

export default EmoteGuesser;

/*
  <div className={"buttonGroup"}>
                        <div className={"moreButtons"}>
                            <Button width={"40%"} size="sm" variant={"outline-primary"} href="https://github.com/zgast/EmoteGuesser">APP SOURCE</Button>
                            <Button width={"40%"}  size="sm"  variant={"outline-primary"} href="https://github.com/zgast/EmoteGuesserBackend">BACKEND SOURCE</Button>
                            <Button width={"40%"}  size="sm"  variant={"outline-primary"} href="https://play.google.com/store/apps/details?id=at.twitch_emote">GOOGLE PLAY</Button>
                            <Button width={"40%"}  size="sm"  variant={"outline-primary"} href="https://apps.apple.com/at/app/emoteguesser/id1578166484">APP STORE</Button>
                        </div>
                    </div>
 */