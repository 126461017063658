import './Banner.css';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";
import {Button} from "react-bootstrap";

function Banner() {
    const {height, width} = useWindowDimensions();
    let className = "";
    let spacerHeight = 0;
    let buttonsClassName = "";
    if (width < 900) {
        spacerHeight = 200;
        className = "BannerMobile2";
        buttonsClassName = "buttons3"

    } else if (width < 1500) {
        spacerHeight = 200;
        className = "BannerMobile1";
        buttonsClassName = "buttons2"
    } else {
        spacerHeight = 0;
        className = "Banner";
        buttonsClassName = "buttons1"


    }

    return (
        <div style={{height: height}} className={className}>
            <div style={{height: spacerHeight}}/>
            < div className={"Header"} id={"home"}/>

            <div className={"spacer2"}/>
            <div className={buttonsClassName}>

                <div className={"button"}>
                    <Button variant="outline-primary" size="lg" href="#about-me">
                        About me
                    </Button>
                </div>
                <div className={"button"}>
                    <Button variant="outline-primary" size="lg" href="#projects">
                        Projects
                    </Button>
                </div>


            </div>

        </div>

    )
        ;
}

export default Banner
;
