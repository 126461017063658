import '../SingleProjects.css';
import logo from '../../../assets/projects/Apartment.png';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";

function Apartment() {
    const {width} = useWindowDimensions();
    return (
        <div className={"about"}>
            <div>
                <div style={{height: "40px"}} id={"about-me"}/>
                <div className={"single-projectHeading-left"}>
                    Apartment Flavia Solva
                </div>

                <div style={{height: "30px"}}/>

                <div className={"single-projectContent-left"}>
                    <img src={logo} alt="Logo" height={"20%"} width={"20%"}/>
                    <div style={width > 1428 ? {width: "65%"} : {width: "100%"}} className={"single-projectText-left"}>
                    This is a Website to advertise the Wein Kultur Apartment Flavia Solva. It is written in nextJS to provide the fastest website speed that's currently possible and to have the best possible Google ranking. As component library I used MUI. I developed this Website in about 2,5 days.
                        <br />
                        <br />
                        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />

                        <a style={{color:"#262626", marginRight:"2%"}} href="https://apartment-flavia-solva.at"><i className="fa fa-solid fa-globe fa-2x"></i></a>


                    </div>
                </div>
            </div>






        </div>

    )
        ;
}

export default Apartment;
