import './MainPage.css';
import Banner from "../components/static/Banner/Banner";
import About from "../components/content/About/About";
import Footer from "../components/static/Footer/Footer";
import Techstack from "../components/content/Techstack/Techstack";
import Projects from "../components/projects/Projects";
import Bar from "../components/static/Bar/Bar";

function MainPage() {
    return (
        <div>
            <Bar/>
            <Banner/>

            <div className={"pageContent"}>
                <div className={"content"}>
                    <About/>
                    <Techstack/>
                    <Projects/>
                </div>
            </div>

            
            <Footer/>
        </div>
    );
}

export default MainPage;
