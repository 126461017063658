import './About.css';
import logo from '../../../assets/about.png';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";

function About() {
    const {width} = useWindowDimensions();

    return (
        <div className={"about"}>
            <div style={{height: "70px"}} id={"about-me"}/>
            <div className={"aboutHeading"}>
                About me
            </div>
            <div style={{height: "100px"}}/>
            <div className={"aboutContent"}>
                <img src={logo} alt="Logo" height={"20%"} width={"20%"}/>
                <div style={width > 1428 ? {width: "65%"} : {width: "100%"}} className={"text"}>
                Hi, I am Markus. 
                <br />
                A young and passionate software engineer at the age of 17.
                <br />
When I started coding around 3 years ago, I quickly became bored of the basic tasks I did in in my school and wanted to become a more advanced programmer. This led to me, starting to develop software in my free time using Java, and has now become one of my greatest hobbies I am most passionate about.
<br />
The start of my Linux story was when I purchased my first server to deploy my self-coded applications. Linux is now for me a daily-use tool on my Desktop PC, Laptop, and of course on all of my servers.
<br />
Due to my ongoing will to learn something new, I started learning Flutter. My first cross-platform project was "EmoteGuesser". After I published the alpha version on Github, I had quickly gained 10 stars and got some Pull Requests without asking. Until now, my tech stack got way more complex, and I learned a lot of new things too to master the requirements of modern and professional software development. I am happy to welcome you on my website, feel free to check out the rest of my portfolio to find out everything about me!
                    <br />
                    <a style={{color:"#262626", marginRight:"2%"}} href="mailto:contact@zgast.at"><i className="fas fa-envelope fa-2x" /></a>

                    <a style={{color:"#262626", marginRight:"2%"}} href="https://github.com/zgast/"><i className="fab fa-github fa-2x"/></a>

                    <a style={{color:"#262626"}} href="https://hub.docker.com/u/zgast/"><i className="fab fa-docker fa-2x"></i></a>


                </div>


            </div>


        </div>
    );
}

export default About;
