import './Footer.css';

function Footer() {
    return (
        <div className={"footer"}>
            <div style={{height: "7%"}}/>
            <div className={"footerContent"}> Copyright Markus Zirngast @2021</div>
        </div>
    );
}

export default Footer;
