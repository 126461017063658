import './Projects.css';
import EmoteGuesser from "./single-projects/EmoteGuesser";
import Tryjump from "./single-projects/Tryjump";
import Apartment from "./single-projects/Apartment";
import HomeOverview from './single-projects/HomeOverview';

function Footer() {
    return (
        <div className={"ProjectContent"}>
            <div style={{height: "150px"}} id={"projects"}/>

            <div className={"heading"}>
                Projects
            </div>

            <div>
                <EmoteGuesser/>
                

                <Tryjump/>
                <Apartment/>
                <HomeOverview/>
            </div>
        </div>
    );
}

export default Footer;
