import './Techstack.css';
import {Card} from "react-bootstrap";
import useWindowDimensions from "../../../helpers/useWindowsDimensions";

function Techstack() {
    let className = "";
    const {width} = useWindowDimensions();
    if (width < 1273) {
        className = "mobileContent";
    } else {
        className = "desktopContent";
    }
    return (
        <div className={"techstack"}>
            <div style={{height: "150px"}}/>

            <div className={"techstackHeading"} id={"techstack"}>
                Techstack
            </div>
            <div style={{height: "150px"}}/>

            <div className={"techstackContent"}>
                <div className={className}>

                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>
                                <Card.Img variant="top" src="https://www.svgrepo.com/show/184143/java.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>

                            <Card.Body>
                                <div className={"techstackCardHeading"}>Java</div>

                            </Card.Body>
                        </Card>
                    </div>

                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/353528/c.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>C</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/353751/flutter.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Flutter</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/354302/rust.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Rust</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/349437/linux.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Linux</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/353778/git.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Git</div>

                            </Card.Body>
                        </Card>
                    </div>


                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>
                                <Card.Img variant="top" src="https://www.svgrepo.com/show/354926/docker.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>

                            <Card.Body>
                                <div className={"techstackCardHeading"}>Docker</div>

                            </Card.Body>
                        </Card>
                    </div>

                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/354259/react.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>ReactJS</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/376359/bash.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Bash</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/376350/spring.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Spring</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/354115/nginx.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>nginx</div>

                            </Card.Body>
                        </Card>
                    </div>
                    <div className={"techstackCard"}>
                        <Card style={{width: '17rem'}}>
                            <div className={"techstackCardImage"}>

                                <Card.Img variant="top" src="https://www.svgrepo.com/show/303600/typescript-logo.svg"
                                          height={"150px"}
                                          width={"150px"}/>
                            </div>
                            <Card.Body>
                                <div className={"techstackCardHeading"}>Typescript</div>

                            </Card.Body>
                        </Card>
                    </div>

                </div>


            </div>
            <div style={{height: "15%"}}/>
        </div>
    );
}

export default Techstack;
