import '../SingleProjects.css';
import logo from '../../../assets/projects/TryJump.png';
import useWindowDimensions from "../../../helpers/useWindowsDimensions";

function TryJump() {
    const {width} = useWindowDimensions();

    return (
        <div className={"about"}>
            <div style={{height: "40px"}} id={"about-me"}/>
            <div className={"single-projectHeading-left"}>
                TryJump
            </div>
            <div style={{height: "30px"}}/>
            <div className={"single-projectContent-left"}>

                <img src={logo} alt="Logo" height={"20%"} width={"20%"} className={"img-left"}/>

                <div style={width > 1428 ? {width: "65%"} : {width: "100%"}} className={"single-projectText-left"}>
                TryJump is a project that I developed with Gebes. Initially, it was a school assignment where we should code atari breakout with Java Swing and we both thought it would be too boring and decided to code a Minecraft clone. This clone is based on the game mode "TryJump" that was invented by the BergwerkLabs server and it was a game mode that we both used to play when we were highly grinding Minecraft in our earlier days.

                    <br />
                    <br />
                    <div className={"buttonGroup"}>

                        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />

                        <a style={{color:"#262626", marginRight:"2%"}} href="https://github.com/Gebes/TryJump/"><i className="fab fa-github fa-2x"></i></a>
                        <a style={{color:"#262626"}} href="https://github.com/Gebes/TryJump/releases/tag/1.0"><i className="fas fa-file-download fa-2x"></i></a>


                    </div>


                </div>


            </div>



        </div>
    )
        ;
}

export default TryJump;
